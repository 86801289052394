import { CorradoProcess } from "@apis/process";
import { isTestStage, isTrackingEnabled } from "@common/env";
import { ErrorBoundary, ScrollToTop } from "@components/common";
import { AdobeTracking } from "@components/common/AdobeTracking";
import { FootnotesProvider } from "@features/footnotes";
import { initializeLogging } from "@features/logging";
import { LogLevelDesc } from "loglevel";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { LoadingPage } from "./product-independent-pages/pages";
import { ProductIndependentPageRoutes } from "./product-independent-pages/routes";
import { PRODUCTS } from "./products";

import "@vwfs-bronson/bronson-bluelabel/dist/css/style.css";
import "./App.css";

const logLevel: LogLevelDesc = isTestStage() ? "DEBUG" : "ERROR";
initializeLogging(logLevel, true);

const QueryParamPrefillPage = React.lazy(() => import("./product-independent-pages/pages/QueryParamPrefillPage"));
const GetProcessPage = React.lazy(() => import("./product-independent-pages/pages/GetProcessPage"));
const TestPage = React.lazy(() => import("./product-independent-pages/pages/TestPage"));
const CookieConsent = React.lazy(() => import("./components/cookie-consent/CookieConsent"));

const App = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <FootnotesProvider>
        <RoutedApp />
      </FootnotesProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

const RoutedApp = () => {
  const navigate = useNavigate();
  const [corradoProcess, setCorradoProcess] = useState<CorradoProcess>();

  const trackingEnabled = isTrackingEnabled();

  return (
    <>
      <ScrollToTop>
        <React.Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route index={true} element={isTestStage() ? <Navigate to="/test" replace={true} /> : <RedirectToProductOverview />} />
            {PRODUCTS.map((product) => (
              <Route key={product.productPath} path={`${product.productPath}/*`} element={<product.component prefilledCorradoProcess={corradoProcess} />} />
            ))}
            {PRODUCTS.map((product) => (
              <Route key={product.productPath} path={`/prefill${product.productPath}`} element={<QueryParamPrefillPage product={product} />} />
            ))}
            <Route
              path="/prefill/:processId"
              element={
                <GetProcessPage
                  onGetProcessSucceeded={(corradoProcess: CorradoProcess) => {
                    setCorradoProcess(corradoProcess);
                    const product = PRODUCTS.find((product) => product.prefillProduct === corradoProcess.data.product);
                    if (product) {
                      navigate(product.productPath, { replace: true });
                    } else {
                      navigate("/error", { replace: true });
                    }
                  }}
                />
              }
            />
            {isTestStage() && <Route path="/test" element={<TestPage />} />}
            {isTestStage() && <Route path="/prefill" element={<NavigateWithSearch to="/test" />} />}
            <Route path="*" element={<ProductIndependentPageRoutes />} />
          </Routes>
        </React.Suspense>
      </ScrollToTop>
      {trackingEnabled && (
        <>
          <React.Suspense fallback={null}>
            <CookieConsent />
            <AdobeTracking />
          </React.Suspense>
        </>
      )}
    </>
  );
};

const NavigateWithSearch = ({ to }: { to: string }) => {
  const { search } = useLocation();
  return <Navigate to={`${to}${search}`} replace={true} />;
};

const RedirectToProductOverview = () => {
  useEffect(() => {
    window.location.replace("https://www.vwfs.de");
  }, []);

  return null;
};

export default App;
