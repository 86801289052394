export const getCookie = (name: string) =>
  document.cookie
    .split(";")
    .map((c) => c.trim())
    .filter((cookie) => {
      return cookie.substring(0, name.length + 1) === `${name}=`;
    })
    .map((cookie) => {
      return decodeURIComponent(cookie.substring(name.length + 1));
    })[0] || null;

export const setCookie = (name: string, value: string, expireDate: Date) => {
  document.cookie = `${name}=${value}; path=/; expires=${expireDate.toUTCString()};`;
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
