import { BackToTop, Footer } from "@vwfs-bronson/bronson-react";
import { Footnotes } from "./Footnotes";

export const PageFooter = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <BackToTop>Back to top</BackToTop>
      <Footer>
        <Footer.ModuleBreadcrumbs />
        <Footer.ModuleMetaNav copyrightText={`Volkswagen Bank GmbH ${year}`}>
          <Footer.ModuleMetaNav.Item href="https://www.vwfs.de/service/preise-konditionen.html" normalLink={true} linkProps={{ target: "_blank" }}>
            Preise &amp; Konditionen
          </Footer.ModuleMetaNav.Item>
          <Footer.ModuleMetaNav.Item href="https://www.vwfs.de/service/impressum.html" normalLink={true} linkProps={{ target: "_blank" }}>
            Impressum
          </Footer.ModuleMetaNav.Item>
          <Footer.ModuleMetaNav.Item href="https://www.vwfs.de/service/rechtliches/datenschutz.html" normalLink={true} linkProps={{ target: "_blank" }}>
            Datenschutz
          </Footer.ModuleMetaNav.Item>
          <Footer.ModuleMetaNav.Item href="/licenses" linkProps={{ target: "_blank" }}>
            Lizenzhinweise Dritter
          </Footer.ModuleMetaNav.Item>
          <Footer.ModuleMetaNav.Item href="https://www.vwfs.de/" normalLink={true} linkProps={{ target: "_blank" }}>
            Volkswagen Financial Services AG
          </Footer.ModuleMetaNav.Item>
        </Footer.ModuleMetaNav>
        <Footer.ModuleFootnotes>
          <Footnotes />
        </Footer.ModuleFootnotes>
      </Footer>
    </>
  );
};
