import classNames from "classnames";
import * as React from "react";
import "./PageHeader.css";
import LogoDesktop from "./images/vwfs-default-logo_desktop.svg";
import LogoMobile from "./images/vwfs-default-logo_mobile.svg";

export interface PageHeaderProps {
  documentTitle?: string;
  showHeroTeaser?: boolean;
  heroTeaserImage?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  phoneNumber?: { url: string; text: string; showInHeader: boolean };
}

export const PageHeader = (props: PageHeaderProps) => {
  const { documentTitle, showHeroTeaser, title, phoneNumber, heroTeaserImage, subTitle } = props;

  const headerPhoneNumber = phoneNumber?.showInHeader ? phoneNumber : undefined;

  const docTitle = documentTitle ? documentTitle : title;
  const headerTitle = !showHeroTeaser ? title : undefined;
  const isHeaderStatic = !showHeroTeaser;

  React.useEffect(() => {
    document.title = `${docTitle ? `${docTitle} - ` : ""}Volkswagen Bank GmbH`;
  }, [docTitle]);

  return (
    <>
      <header className="c-header__wrapper">
        <div
          className={classNames("c-header", {
            "c-header--static": isHeaderStatic,
          })}
        >
          <div className="c-header__content-wrapper">
            <div className="c-header__logo">
              <a className="c-logo c-logo--has-image" href="https://www.vwfs.de/">
                <picture>
                  <source srcSet={LogoMobile} media="(max-width: 720px)" />
                  <img src={LogoDesktop} className="c-logo__image" alt="Logo" />
                </picture>
              </a>
            </div>

            {(title || headerPhoneNumber) && (
              <div className="c-context-bar">
                <div className="c-context-bar__panel" aria-hidden="true">
                  <nav className="c-context-bar__navs" aria-label="primary">
                    <ul className="c-context-bar__nav">
                      {title && (
                        <li>
                          <span className="c-context-bar__title">{headerTitle}</span>
                        </li>
                      )}
                    </ul>
                    <ul className="c-context-bar__nav">
                      {headerPhoneNumber && (
                        <>
                          <li>
                            <a className="c-context-bar__link" href={headerPhoneNumber.url}>
                              <span>{headerPhoneNumber.text}</span>
                            </a>
                          </li>
                          <li>
                            <a className="c-context-bar__link" href={headerPhoneNumber.url}>
                              <i className="c-icon c-icon--[semantic-phone] c-context-bar__icon" />
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      <div className="o-page-wrap b1-header-title">
        <h1>{headerTitle}</h1>
      </div>

      {showHeroTeaser && (
        <div className="c-hero-teaser c-hero-teaser--auto">
          {heroTeaserImage ? <img className="c-hero-teaser__image" src={heroTeaserImage} alt={headerTitle?.toString()} /> : <div className="c-hero-teaser__image" />}

          <div className="c-hero-teaser__content">
            <div className="c-hero-teaser__content-inner">
              <header className="c-hero-teaser__heading">
                <div className="c-hero-teaser__title">
                  <h1>{title && <span className="c-hero-teaser__title-row">{title}</span>}</h1>
                </div>

                {subTitle && (
                  <div className="c-hero-teaser__subtitle">
                    <p>{subTitle}</p>
                  </div>
                )}
              </header>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
