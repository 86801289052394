export enum UploadItemState {
  normal = "",
  loading = "is-loading",
  success = "is-success",
  error = "is-error",
}

export enum UploadItemType {
  pdf = "document-pdf",
  jpg = "document-jpg",
  other = "document-arrow",
}

export type PageWrapSize = "medium" | "small" | "xsmall" | "xxsmall";

export type AllowedFraction =
  | "1/1"
  | "1/3"
  | "2/3"
  | "3/3"
  | "1/2"
  | "2/2"
  | "1/4"
  | "2/4"
  | "3/4"
  | "4/4"
  | "1/5"
  | "2/5"
  | "3/5"
  | "4/5"
  | "5/5"
  | "1/6"
  | "2/6"
  | "3/6"
  | "4/6"
  | "5/6"
  | "6/6"
  | "1/12"
  | "2/12"
  | "3/12"
  | "4/12"
  | "5/12"
  | "6/12"
  | "7/12"
  | "8/12"
  | "9/12"
  | "10/12"
  | "11/12"
  | "12/12";
