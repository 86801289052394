import { useLocationState } from "@common/location-state";
import { ButtonLink } from "@components/bronson/ButtonLink";
import { CommonPage, GeneralError } from "@components/common";

export interface ErrorPageLocationState {
  errorMessage?: string;
}

export const ErrorPage = () => {
  const { errorMessage } = useLocationState<ErrorPageLocationState>();

  return (
    <CommonPage>
      <GeneralError errorMessage={errorMessage ?? "Entweder wurde die Verbindung unterbrochen oder der Server ist vorübergehend nicht erreichbar."} />
      <ButtonLink href="https://www.volkswagenbank.de">Zur Webseite der Volkswagen Bank</ButtonLink>
    </CommonPage>
  );
};
