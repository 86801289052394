import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ErrorPage, LoadingPage } from "../pages";

const AccountCheckResultPage = React.lazy(() => import("../pages/AccountCheckResultPage"));
const ConfirmMevPage = React.lazy(() => import("../pages/ConfirmMevPage"));
const LegitimationPage = React.lazy(() => import("../pages/LegitimationPage"));
const VollmachtPage = React.lazy(() => import("../pages/VollmachtPage"));
const VollmachtLegitimationPage = React.lazy(() => import("../pages/VollmachtLegitimationPage"));
const LicensesPage = React.lazy(() => import("../pages/LicensesPage"));

export const ProductIndependentPageRoutes = () => (
  <Routes>
    <Route path="error" element={<ErrorPage />} />
    <Route path="loading" element={<LoadingPage />} />
    <Route path="account-check" element={<AccountCheckResultPage />} />
    <Route path="confirm-mev" element={<ConfirmMevPage />} />
    <Route path="legitimation" element={<LegitimationPage />} />
    <Route path="vollmacht" element={<VollmachtPage />} />
    <Route path="vollmacht-legitimation" element={<VollmachtLegitimationPage />} />
    <Route path="licenses" element={<LicensesPage />} />
    <Route path="*" element={<Navigate to="/error" replace={true} />} />
  </Routes>
);
