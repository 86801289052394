import { BANKEINS_API } from "@apis/common";
import { FrontendError, FrontendErrorType } from "@common/frontend-error";

const TIMEOUT = 5000;

interface ErrorReportingRequest extends FrontendErrorType {
  userAgent?: string;
  referrer?: string;
}

export class ErrorReportingAPI {
  static async reportError(frontendError: FrontendError): Promise<void> {
    try {
      const data: ErrorReportingRequest = { ...frontendError, message: frontendError.message, userAgent: navigator.userAgent || undefined, referrer: document.referrer || undefined };
      // TODO: Should use different API
      await BANKEINS_API.post("/application/report", data, {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: TIMEOUT,
      });
    } catch (_) {
      // intentionally left blank
    }
  }
}
