import { IconName } from "@components/bronson";
import { Button } from "@vwfs-bronson/bronson-react";
import { PropsWithChildren } from "react";

interface Props {
  href: string;
  target?: string;
}

export const ButtonLink: React.FC<PropsWithChildren<Props>> = ({ href, target, children }) => (
  <Button element="a" href={href} target={target ?? "_self"} link={true} small={true} icon={IconName.FORWARD}>
    {children}
  </Button>
);
