interface Props {
  errorMessage: string;
}

export const GeneralError = ({ errorMessage }: Props) => (
  <>
    <h3>Leider ist ein Fehler aufgetreten.</h3>

    <p>{errorMessage}</p>

    <p>
      <strong>Bitte versuchen Sie es später noch einmal.</strong>
    </p>
  </>
);
