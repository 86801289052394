import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

type ErrorType = "UncaughtError" | "WebCalcFailed" | "GetProcessFailed" | "APICallFailed" | "Other";

export interface FrontendErrorType {
  type: ErrorType;
  message: string;
  pathname: string;
  originalErrorMessage?: string;
  originalErrorCode?: string;
  apiCall?: {
    url?: string;
    httpStatus?: number;
    httpStatusText?: string;
    payload?: string;
  };
}

export class FrontendError extends Error implements FrontendErrorType {
  type: ErrorType;
  pathname: string;
  originalErrorMessage?: string;
  originalErrorCode?: string;
  apiCall?: {
    url?: string;
    httpStatus?: number;
    httpStatusText?: string;
    payload?: string;
  };

  constructor(
    type: ErrorType,
    message: string,
    originalError?: unknown,
    details?: {
      originalErrorCode?: string;
      axiosResponse?: AxiosResponse;
      includePayload?: boolean;
    },
  ) {
    super(message);

    this.type = type;
    this.pathname = window.location.pathname;
    this.originalErrorMessage = originalError instanceof Error ? originalError.message : undefined;
    this.originalErrorCode = details?.originalErrorCode;

    let requestConfig: AxiosRequestConfig | undefined;
    let httpStatus: number | undefined;
    let httpStatusText: string | undefined;

    if (axios.isAxiosError(originalError)) {
      requestConfig = originalError.config;
      httpStatus = originalError.response?.status;
      httpStatusText = originalError.response?.statusText || undefined;
    } else if (details?.axiosResponse) {
      requestConfig = details.axiosResponse.config;
      httpStatus = details.axiosResponse.status;
      httpStatusText = details.axiosResponse.statusText || undefined;
    }

    if (requestConfig?.url) {
      const url = `${requestConfig.baseURL ?? ""}${requestConfig.url ?? ""}`;
      this.apiCall = {
        url,
        httpStatus,
        httpStatusText,
      };

      if (details?.includePayload && requestConfig.data) {
        this.apiCall.payload = typeof requestConfig.data === "string" ? requestConfig.data : JSON.stringify(requestConfig.data);
      }
    }
  }

  public toString = (): string => `${this.message}${this.originalErrorMessage ? `, ${this.originalErrorMessage}${this.originalErrorCode ? ` (error code: ${this.originalErrorCode})` : ""}` : ""}`;
}
