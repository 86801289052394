import classNames from "classnames";
import { PropsWithChildren } from "react";

interface StepsItemProps {
  icon: string;
  title: string;
  text?: string;
}

const StepsItem: React.FC<PropsWithChildren<StepsItemProps>> = ({ icon, title, text, children }) => {
  return (
    <div className="c-steps__item">
      <div className="c-steps__icon">
        <i className={`c-icon c-icon--[${icon}]`} />
      </div>
      <div className="c-steps__body">
        <p className="c-steps__title">{title}</p>
        {text && <p className="c-steps__text">{text}</p>}
        {children}
      </div>
    </div>
  );
};

interface StepsConnectorProps {
  icon: string;
}

const StepsConnector = ({ icon }: StepsConnectorProps) => <i className={`c-steps__connector c-icon c-icon--[${icon}]`} />;

interface StepsProps {
  className: string;
}

export const Steps: React.FC<PropsWithChildren<StepsProps>> & {
  Item: typeof StepsItem;
  Connector: typeof StepsConnector;
} = ({ className, children }) => {
  return <div className={classNames("c-steps", className)}>{children}</div>;
};

Steps.Item = StepsItem;
Steps.Connector = StepsConnector;
