import { scrollToTop } from "@common/scroll";
import { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname, search]);

  return <>{children}</>;
};
