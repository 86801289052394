import { useFootnotes } from "@features/footnotes";
import { Footnote } from "@vwfs-bronson/bronson-react";
import React from "react";

export const Footnotes = () => {
  const { footnotes } = useFootnotes();

  const sortedFootnotes = Object.keys(footnotes ?? {})
    .map((text) => ({ ...footnotes[text], text }))
    .filter((footnote) => footnote.usages > 0)
    .sort((fn1, fn2) => fn1.index - fn2.index);

  return (
    <Footnote>
      {sortedFootnotes.map((footnote) => (
        <Footnote.Item key={footnote.index} bullet={`${footnote.index}`}>
          <a id={`footnote-${footnote.index}`} />
          {footnote.text?.split("\n").map((token, index, arr) => (
            <React.Fragment key={`footnote-text-${index}`}>
              {token && React.createElement("span", null, token)}
              {index < arr.length - 1 && React.createElement("br")}
            </React.Fragment>
          ))}
        </Footnote.Item>
      ))}
    </Footnote>
  );
};
