import { FrontendError } from "@common/frontend-error";
import { Button } from "@vwfs-bronson/bronson-react";
import log from "loglevel";
import { Component, ErrorInfo, ReactNode } from "react";
import { CommonPage, GeneralError } from ".";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.error(new FrontendError("UncaughtError", [error.message, errorInfo.componentStack].join(", ")));
  }

  public render() {
    if (this.state.hasError) {
      return (
        <CommonPage>
          <GeneralError errorMessage="Entweder wurde die Verbindung unterbrochen oder der Server ist vorübergehend nicht erreichbar." />
          <Button type="button" onClick={() => window.location.reload()}>
            Seite neu laden
          </Button>
        </CommonPage>
      );
    }

    return this.props.children;
  }
}
