import { ErrorReportingAPI } from "@apis/error-reporting";
import { FrontendError } from "@common/frontend-error";
import log, { LogLevelDesc } from "loglevel";

export const initializeLogging = (logLevelDesc: LogLevelDesc, enableRemoteLogging: boolean) => {
  if (enableRemoteLogging) {
    const originalFactory = log.methodFactory;
    log.methodFactory = (methodName, logLevel, loggerName) => {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (...messages: any[]) => {
        let frontendError: FrontendError;
        if (messages.length === 1 && messages[0] instanceof FrontendError) {
          frontendError = messages[0];
        } else {
          frontendError = new FrontendError("Other", messages.join(", "));
        }
        rawMethod(frontendError.toString());
        if (methodName.toUpperCase() === "ERROR") {
          ErrorReportingAPI.reportError(frontendError);
        }
      };
    };
  }

  log.setLevel(logLevelDesc, false);
};
